input.search-input{
    width: 40%;
    padding: 10px 10px;
    font-size: 16px;
    color: #ffffff;
    border: 1px solid #989696;
    border-radius: 20px;
    background: rgba(0, 0, 0, 0.318);
    user-select: none;
    margin-right: 3%;
    align-self: end;
    margin-bottom: 1rem;
}
img{
    margin: 1rem 0;
}
input:focus::placeholder{
    color: transparent;
}
.navBar {
    display: flex;
    align-items: center;
    align-content: space-around;
}
.navButton {
    border: none;
    background: #b1bfff;
    color: black;
    padding: 12px 54px;
    margin: 8px;
    margin-top: 20px;
    font-size: 15px;
    cursor: pointer;
    user-select: none;
}