*{box-sizing: border-box;}
.schedule-box{
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    border: 3px solid black;
    border-radius: 3rem;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.388);
    text-align: center;
}
.schedule-box div:not(:last-child){
    border-right: 2px solid #000000;
}



