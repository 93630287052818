input.create-buttons{
    width: 100%;
    color: black;
    font-size: 2rem;
    background-color: rgba(199, 199, 71, 0.787);
    margin-top: 2rem;
    border: 1px solid transparent;
    border-radius: 1rem;
}
input.create-buttons:hover{
    background-color: rgba(231, 231, 74, 0.926);
}