.tree-container{
    margin: 2rem;
}
.tree-container ul{
    list-style-type: none;
    border: none;
}
.tree-container li{
    font-size: 1.3rem;
    border: none;
}
.tree-item{
    background-color: transparent;
    font-size: 1.3rem;
    border: none;
}
.tree-item:hover{
    background-color: rgba(163, 162, 162, 0.474);
}
.tree-item + ul{
    margin-left: 1rem;
}