/* Reset CSS */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  /* General Styles */
  body {
    font-family: Arial, sans-serif;
  }
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 3px solid black;
    margin: 0 2rem;
  }
  main{
    display: grid;
    height: 80vh;
    grid-template-columns: 20% 40% auto;
    grid-template-rows: auto auto;
  }
  aside{
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 2;
    border-right: 3px solid black;
  }
  .pie-chart{
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 2;
    grid-column-end: 3;
    margin: 5%;
    align-self: center;
  }
  .button-section{
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 3;
    grid-column-end: 4;
    margin: 5%;
    align-self: center;
  }
  .schedule{
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 2;
    grid-column-end: 4;
    margin:3%;
    text-align: center;
  }
  

  
  